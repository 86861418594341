@use 'src/src/app/scss/abstracts' as *;

.game-card-skeleton {
  @include skeleton;

  border-radius: $border-radius-s;
}

.game-card {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  display: block;
  position: relative;
  transform: translate3d(0, 0, 0) scale(1);

  &:hover {
    #{$block-name} {
      &__media {
        transform: translate3d(0, 0, 0) scale(0.98);
      }

      &__button {
        opacity: 1;
      }
    }
  }

  &:active {
    #{$block-name} {
      &__media {
        transform: translate3d(0, 0, 0) scale(0.96);
      }
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity $transition-slow;
  }

  &__media {
    border-radius: $border-radius-s;
    overflow: hidden;
    transition: transform $transition-slow;

    @include mq($until: md) {
      border-radius: $border-radius-xs;
    }
  }
}
